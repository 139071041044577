import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { ResourcePageContentText, SharedContentText } from '../../models/content-text.model';
import { ViewerPagesContentService } from '../../services/content/viewer-pages-content.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'adapt-viewer-resources',
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss',
})
export class ResourcesComponent implements OnInit {
  public navigation = this.route.queryParams.pipe(map((params) => params?.['navigation'] || 'useful-links'));
  public fragment = this.route.fragment.pipe(filter(val => !!val), startWith('general-understanding'));

  //public fragment = 'general-understanding';

  resourcePageContent: ResourcePageContentText | null;
  resourceSharedContent: SharedContentText | null;


  constructor(public viewerPagesContentService: ViewerPagesContentService, public route: ActivatedRoute) {

  }

  ngOnInit() {

    this.viewerPagesContentService.getViewerContentListener().subscribe((viewerContentText) => {
      console.log('resource ngOnInit, viewer contentText: ', viewerContentText);

      if (viewerContentText) {
        this.resourcePageContent = this.viewerPagesContentService.getPageContent(
          'resources'
        ) as ResourcePageContentText;
        this.resourceSharedContent = this.viewerPagesContentService.getPageContent('shared') as SharedContentText;

        //console.log('resourceSharedContent: ', this.resourceSharedContent);
      }
    });
  }
}
