import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContentService } from '@adapt/adapt-shared-component-lib';
import { ViewerContentText, ResourcePageContentText, HomePageContentText, SharedContentText, ErrorPageContentText, ReportPageContentText } from '../../models/content-text.model'
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class ViewerPagesContentService {
  viewerContentSubject: ReplaySubject<ViewerContentText> = new ReplaySubject<ViewerContentText>(1);
  viewerContent: ViewerContentText;

  constructor(public contentService: ContentService, private storage: StorageService) {}

  // subscribe to the timer service and get the time left before the timer expired
  getViewerContentListener(): Observable<ViewerContentText> {
    return this.viewerContentSubject.asObservable();
  }

  getViewerPageContentListener(page: string){
    return this.viewerContentSubject.asObservable().pipe(map(result => this.getPageContent(page)));
  }

  loadContent() {

    
    //console.log('Inside ViewerPagesContentService.loadContent(), environment.defaultContent: ', environment.defaultContent);
  
    const viewerContent = this.storage.getItem('session', 'viewer_content');

    if (!viewerContent) {
      this.contentService.loadContent(environment.contentRoot, environment.contentFileName, 'en').subscribe((response: ViewerContentText) => {
        //console.log('response: ', response);

        if (response?.pages) {
          this.updateContent(response);
        }
      });
    } else {
      this.updateContent(JSON.parse(viewerContent));
    }
  }

  updateContent(viewerContent: ViewerContentText) {
    this.viewerContent = viewerContent;

    this.storage.setItem('session', 'viewer_content', JSON.stringify(viewerContent))

    //console.log('updateContent: ', viewerContent);
    this.viewerContentSubject.next(viewerContent);
  }

  getPageContent(pageName: string): ResourcePageContentText | HomePageContentText | SharedContentText | ErrorPageContentText | ReportPageContentText | null {
    //const viewerContent = this.viewerContentSubject.getValue();

    //console.log('getPageContent, pageName: ', pageName);

    if (this.viewerContent?.pages) {
      if (pageName === 'shared') {
        return this.viewerContent?.shared!;
      } else if (pageName === 'resources') {
        return this.viewerContent?.pages[pageName] as ResourcePageContentText;
      } else if (pageName === 'home') {
        return this.viewerContent?.pages[pageName] as HomePageContentText;
      }  else if (pageName === 'error') {
        return this.viewerContent?.pages[pageName] as ErrorPageContentText;
      } else if (pageName === 'report'){
        return this.viewerContent?.pages[pageName] as ReportPageContentText;
      }
    }

    return null;
  }
}
