import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-adapt-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrl: './side-panel.component.scss',
})
export class SidePanelComponent {

  public show = false;
  @ViewChild('sidePanel') panel!: ElementRef;

  @Input() title = 'Adapt Side Panel';
  @Input() description = 'Enter some descriptive content here.';

  @Input() direction : 'right' | 'left' = 'right'


  close() {
    this.show = false;
  }

  open(){
    this.show = true;
  }




}
