import { Component } from '@angular/core';
import { ViewerPagesContentService } from '../../services/content/viewer-pages-content.service';
import { ErrorPageContentText } from '../../models/content-text.model';
import { map } from 'rxjs';

@Component({
  selector: 'adapt-viewer-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent {

  public content = this.pages.getViewerContentListener().pipe(map(ctn => this.pages.getPageContent('error') as ErrorPageContentText))

  constructor(public pages: ViewerPagesContentService){

  }

}
