import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private language = new BehaviorSubject('en'); 

  public $language = this.language.asObservable();

  public changeLanguage(lang: string){
    this.language.next(lang);
  }

  public reset(){
    this.language.next('en');
  }

}
