<div class="tab-item" [attr.aria-selected]="isSelected">
  <button
    routerLink="."
    [queryParams]="queryParams"
    role="tab"
    [attr.aria-controls]="navigation.id"
    [ngClass]="{ 'border-bottom-05': selected }"
    class="usa-button usa-button--outline shadow-none">
    {{ name }}
  </button>
</div>
