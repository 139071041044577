import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalComponent } from '@adapt/adapt-shared-component-lib';
import { environment } from 'apps/adapt-admin/src/environments/environment';

import { SettingsService } from '../../../../../adapt-admin/src/app/admin/services/settings.service';
import { DOCUMENT } from '@angular/common';
import { WeissAccessibilityCenterService } from 'weiss-accessibility-center';
import { LanguageService } from '@adapt/adapt-shared-component-lib';
import { ViewerPagesContentService } from '../../services/content/viewer-pages-content.service';
import { SidePanelComponent } from 'libs/adapt-shared-component-lib/src/lib/components/side-panel/side-panel.component';

@Component({
  selector: 'adapt-viewer-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class ViewerSidebarComponent implements AfterViewInit {
  @ViewChild('confirmLogOut') confirmLogOutModal?: ModalComponent;
  @ViewChild('navList') navList!: ElementRef<HTMLUListElement>;
  @ViewChild('languageSelector') languageSelector: SidePanelComponent
  public logoURL?: SafeUrl;
  public skipTo: any;

  public $content = this.content.getViewerContentListener();

  constructor(
    private content: ViewerPagesContentService,
    private router: Router,
    private sanitzier: DomSanitizer,
      private center: WeissAccessibilityCenterService,
    private settings: SettingsService,
    public language: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.settings.getSettingsObservable().subscribe((settings) => {
      this.logoURL = this.sanitzier.bypassSecurityTrustUrl(
        `https://${environment.s3PublicAssetsDomainName}.s3.amazonaws.com/${settings.logo}`
      );
    });

    this.router.events.subscribe((event) => {


      if (event instanceof NavigationEnd) {
        (this.document.querySelector('.skip-to')?.querySelector('button') as HTMLButtonElement)?.focus();
      }
    });

  



  }

  nav_collapsed = true;

  toggleNav() {
    this.nav_collapsed = !this.nav_collapsed;
  }

  ngAfterViewInit(): void {
    this.navList.nativeElement.querySelectorAll('[role="menuitem"]').forEach((item, index, items) => {
      // Count starts at 1 for humans
      const position = index + 1;
      const total = items.length;

      // NVDA and JAWS supports automatic x of n annouce however mac voice over doesn't so we don't need to add these on windows devices
      if (!navigator.userAgent.includes('Mac OS X')) {
        return;
      }

      item.addEventListener('focus', () => {
        const customLabel = `${position} of ${total}`;
        item.setAttribute('aria-roledescription', customLabel);
      });

      item.addEventListener('blur', () => {
        item.removeAttribute('aria-roledescription');
      });
    });
  }

  closeMenuOnNavigation() {
    // if at mobile size, close the menu when a link is clicked
    if (window.innerWidth < 640) {
      this.nav_collapsed = true;
    }
  }

  public signOut() {
    this.confirmLogOutModal?.close();
    // this.user.logout();
  }
}
