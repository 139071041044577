import { AdaptSettings, IGlossaryTerm, LanguageCode, Response } from '@adapt/types';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, map, take } from 'rxjs';
import { API_URL, SettingsService } from '../../index';
@Injectable({
  providedIn: 'root',
})
export class GlossaryService {
  private _glossary = new BehaviorSubject<{[lang: string]: { [key: string]: IGlossaryTerm }}>({});

  constructor(private http: HttpClient, @Inject(API_URL) api: string, private settings: SettingsService) {

    this.settings.getSettingsObservable().pipe(take(1)).subscribe(settings => {

      const langs = settings.supportedLanguages || ['en'];

      for(const lang of langs){

        this.http
        .get<Response<any>> (`${api}/settings/glossary`  .replace(/([^:]\/)\/+/g, "$1"), {params: {lang}})
        .pipe(map(item => item.data.terms))
        .subscribe((result) => this.registerJSON(lang, result as { [key: string]: IGlossaryTerm }));


      }



    })
  

  }

  public registerJSON(lang: LanguageCode, values: { [key: string]: IGlossaryTerm }) {
    const currVal = this._glossary.getValue();

    currVal[lang] = values;

    this._glossary.next(currVal);
  }

  public registerTerm(key: string, value: IGlossaryTerm, lang: LanguageCode = 'en') {
    const currValue = this._glossary.getValue();
    currValue[lang][key] = value;
    this._glossary.next(currValue);
  }

  public hasTerm(key: string, lang = 'en') {
    return this.currValue[lang] && key in this.currValue[lang];
  }

  public getTermSafe(key: string, def?: string, lang: LanguageCode = 'en'): IGlossaryTerm {
    if (!this.hasTerm(key, lang)) {
      return { label: def || key, definition: '' };
    }

    return this.currValue[lang][key] as IGlossaryTerm;
  }

  public getTerm(key: string, lang: LanguageCode = 'en'): IGlossaryTerm | undefined {
    return this.currValue[lang][key] as IGlossaryTerm;
  }

  public getTermObservable(key: string, lang = 'en') {
    return this._glossary.asObservable().pipe(map((glossary) => {
      const glossaryItem = glossary[lang];

      if(!glossaryItem){
        return {label: key, definition: ''};
      }

      return glossary[lang][key];
    }));
  }

  private get currValue() {
    return this._glossary.getValue();
  }
}
