<div *ngIf="content | async as ctn" class="error-page-container height-full display-flex flex-align-center flex-justify-center">

    <div class="error-page-body bg-white padding-2 maxw-mobile-lg tablet:margin-top-0 margin-top-4 flex-justify-center display-flex flex-column gap-1 tablet:radius-lg tablet:border-1px border-base-light">
        <h1 class="margin-0">{{ctn.header}}</h1>

        <p class="usa-prose">{{ctn.description}}</p>

        <a class="margin-0 flex-align-self-start display-flex flex-column flex-justify-center" routerLink="">{{ctn.return}}</a>

    </div>


</div>