<aside
  class="display-flex minh-full height-viewport"
  aria-label="App Sidebar Menu"
  [ngClass]="{ collapsed: nav_collapsed }">
  <section class="sidebar-content display-flex gap-1 bg-white flex-column width-full padding-1">
    <button
      [attr.aria-expanded]="!nav_collapsed"
      type="button"
      class="usa-button gap-0 flex-column usa-button--outline shadow-none"
      id="expand-menu-button"
      (click)="toggleNav()"
      i18n>
      <ng-container *ngIf="nav_collapsed">
        <i aria-hidden="true" class="fal fa-bars"></i>
        <span class="font-3xs"><span class="display-none tablet:display-inline">Open </span>Menu</span>
      </ng-container>

      <ng-container *ngIf="!nav_collapsed">
        <i aria-hidden="true" class="fal fa-arrow-from-right"></i>
        <span class="font-3xs"><span class="display-none tablet:display-inline">Collapse </span>Menu</span>
      </ng-container>
    </button>

    <div class="sidebar-header">
      <div class="display-flex width-full padding-1 gap-1 flex-align-center flex-justify-center">
        <!-- <h1>Some State</h1> -->
        <lib-adapt-inline-svg
          alt="Weiss logo"
          src="assets/shared/logos/weiss-logo.svg"
          svgClass="display-flex width-6"></lib-adapt-inline-svg>
        <!--        <img [src]="assets" class="logo" alt="IDW Logo" />-->

        <!--        <span class="font-md display-none-collapsed">State Name Goes Here</span>-->
      </div>
    </div>

    <nav aria-label="primary" class="margin-top-1">
      <ul #navList role="menubar" aria-orientation="vertical" class="usa-icon-list display-flex flex-column gap-05">
        <!--        <li role="none">-->
        <!--          <a aria-describedby="dhw" role="menuitem" routerLink="/dhw" (click)="closeMenuOnNavigation()"-->
        <!--            routerLinkActive="active-nav hover:bg-primary-dark"-->
        <!--            class="usa-icon-list__item site-nav">-->
        <!--            <lib-adapt-inline-svg alt="IDHW logo" src="assets/shared/logos/idhw-logo-short.svg" svgClass="display-flex width-6"></lib-adapt-inline-svg>-->
        <!--            <span id="dhw"> DHW </span>-->
        <!--          </a>-->

        <!--        </li>-->
        <li role="none">
          <!-- [routerLinkActiveOptions]="{ exact: true }"-->
          <a
            aria-describedby="home"
            role="menuitem"
            routerLink="/"
            (click)="closeMenuOnNavigation()"
            routerLinkActive="active-nav hover:bg-primary-dark"
            [routerLinkActiveOptions]="{ exact: true }"
            class="usa-icon-list__item site-nav">
            <i aria-hidden="true" class="fal fa-home"></i>
            <span id="home"> Home </span>
          </a>
        </li>
        <li role="none">
          <a
            aria-describedby="reports"
            role="menuitem"
            routerLink="reports"
            (click)="closeMenuOnNavigation()"
            routerLinkActive="active-nav hover:bg-primary-dark "
            [routerLinkActiveOptions]="{ exact: true }"
            class="usa-icon-list__item site-nav">
            <i aria-hidden="true" class="fal fa-chart-line"></i>
            <span> Reports </span>
          </a>
        </li>
        <li role="none">
          <a
            aria-describedby="resources"
            role="menuitem"
            routerLink="resources"
            (click)="closeMenuOnNavigation()"
            routerLinkActive="active-nav hover:bg-primary-dark"
            [routerLinkActiveOptions]="{ exact: true }"
            id="resourcesBtn"
            class="usa-icon-list__item site-nav">
            <i aria-hidden="true" class="fal fa-file"></i>
            <span> Resources </span>
          </a>
        </li>
        <li role="none">
          <button role="menuitem" weissA11yToggle class="usa-icon-list__item site-nav width-full">
            <i aria-hidden="true" class="fal fa-universal-access"></i>

            <span> Accessibility </span>
          </button>
        </li>
        <li role="none"></li>
      </ul>
    </nav>
    <span class="margin-top-auto display-flex width-full flex-justify-center">
      <lib-adapt-inline-svg
        *ngIf="!nav_collapsed"
        alt="ADAPT logo"
        src="assets/shared/svg/ADAPT_Title_Full.svg"
        svgClass="display-flex width-15"></lib-adapt-inline-svg>
      <lib-adapt-inline-svg
        *ngIf="nav_collapsed"
        alt="ADAPT logo"
        src="assets/shared/svg/ADAPT_Mark_Full.svg"
        svgClass="display-flex width-5"></lib-adapt-inline-svg>
    </span>
  </section>
</aside>

<lib-adapt-modal closeText="Close" heading="Are you sure you want to sign out?" #confirmLogOut>
  <div class="confirm-modal-body display-flex flex-column gap-2 padding-1">
    <div class="confirm-modal-footer padding-1 display-flex gap-2">
      <button class="usa-button" (click)="signOut()">Sign out</button>
      <button class="usa-button usa-button--unstyled" (click)="confirmLogOut.close()">Cancel</button>
    </div>
  </div>
</lib-adapt-modal>
