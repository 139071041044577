export const environment = {
  API_URL: 'https://dev.api.adaptdata.org/',
  VAPID_KEY: 'BMB5Oyi3ivmP-PgHj_cLtDfv_Zkoy5uMQWd0QqAbLci-LuaJ3kHvnCF37XlgeW5WPTOAHbbmmBEOW-wuigbLAYU',
  cognitoRegion: 'us-east-1',
  cognitoDomainName: 'dev-adaptadmin',
  clientId: '34e7oadg3u1d2l74sbfc5prqm2',
  s3PublicAssetsDomainName: 'dev-adaptpublicassetsbucket',
  contentRoot: 'assets/text',
  contentFileName: 'admin-content-text.json',
  enforceLogin: true,
  envLabel: 'LOCAL',
  enforceRole: true,
  callbackUrl: 'http://localhost:4200/auth/redirect',
  Cognito: {
    userPoolId: 'us-east-1_0btrr1IZq',
    userPoolClientId: '34e7oadg3u1d2l74sbfc5prqm2',
  },
  defaultContent: 'assets/content-labels.json',
  loginContent: 'assets/content-labels.json',
  pagesContent: 'assets/text/admin-content-text.json',
};
