<div *ngIf="content" class="data-rep-grouped">
  <div class="display-flex flex-1 flex-column gap-1 padding-105 bg-base-lighter radius-md">
    <lib-adapt-h-element class="quick-filter-title" [level]="mapHeadingLvl(headingLvl2)"
      >{{content.actions!['quick_filters']}}</lib-adapt-h-element
    >
    <p>{{ raw.filterDescription || 'Description' }}</p>
    <ul
      class="usa-list usa-list--unstyled display-flex margin-top-105 flex-column gap-1 padding-left-105 border-left-2px border-base-light">
      <li>
        <button
          class="usa-button usa-button--unstyled text-no-underline flex-align-center display-flex"
          [ngClass]="{ 'text-bold': raw.title === header }"
          (click)="applyQuickFilter('all')">
          {{ raw.title ?? raw.name }}
        </button>
      </li>
      <li *ngFor="let item of quickFilters; index as index">

        <button
          class="usa-button usa-button--unstyled text-no-underline flex-align-center display-flex"
          [ngClass]="{ 'text-bold': item === header }"
          (click)="applyQuickFilter(item)">
          {{ item | glossary: 'label': lang | async }}
        </button>
        <p
          class="font-xs line-height-3 margin-bottom-1 margin-top-05"
          [hidden]="dataRepSettings.showGlossary === true ? undefined : true"
          [attr.aria-expanded]="dataRepSettings.showGlossary"
          [id]="id + '-quickfilter-item-definition-' + index">
          <span [innerHTML]="item | glossary : 'definition' : lang | async"></span>
        </p>
      </li>
    </ul>
  </div>

  <article
    class="data-rep-wrapper flex-2"
    [attr.aria-labelledby]="id + '-title'"
    [attr.aria-describedby]="id + '-insight' + id + '-total'">
    <lib-adapt-h-element class="title" [level]="mapHeadingLvl(headingLvl)">{{
      header | glossary: 'label': lang | async
    }}</lib-adapt-h-element>
    <p class="insight" [innerHTML]="insight"></p>

    <ng-container *ngIf="{ noData: isNoData() } as loaded">
      <div class="action-bar">
        <ul *ngIf="!loaded.noData" role="group">
          <li>
            <button
              (click)="togglePlainLanguage()"
              #explanationSwitch
              [attr.aria-controls]="id + '-explanation-region'"
              aria-pressed="false"
              [id]="id + '-explanation-switch'">
              <i
                class="far"
                [ngClass]="{
                  'fa-toggle-off': !dataRepSettings.showPlainLanguage,
                  'fa-toggle-on': dataRepSettings.showPlainLanguage
                }"
                aria-hidden="true"></i>
             {{content.actions!['explain']}}
            </button>
          </li>
          <li>
            <button
              (click)="toggleGlossary()"
              #glossarySwitch
              [attr.aria-controls]="glossaryIdsString"
              aria-pressed="false"
              [id]="id + '-glossary-switch'">
              <i
                class="far"
                [ngClass]="{
                  'fa-toggle-off': !dataRepSettings.showGlossary,
                  'fa-toggle-on': dataRepSettings.showGlossary
                }"
                aria-hidden="true"></i>
                {{content.actions!['glossary']}}
            </button>
          </li>
          <li>
            <button #dataModalSwitch [id]="id + '-data-modal-button'" (click)="openDataModal()">
              <i class="far fa-table" aria-hidden="true"></i>
              {{content.actions!['data']}}
            </button>
          </li>
          <!-- <button>
            <i class="fal fa-share"></i>
            Share
        </button> -->
        </ul>
      </div>
      <div
        class="plain-language"
        #explainationRegion
        [attr.aria-pressed]="dataRepSettings.showPlainLanguage"
        [attr.aria-expanded]="dataRepSettings.showPlainLanguage"
        [hidden]="dataRepSettings.showPlainLanguage === true ? undefined : true"
        tabindex="-1">
        <lib-adapt-h-element class="plain-language-title" [level]="mapHeadingLvl(headingLvl2)"
          >   {{content.actions!['plain_language_summary']}}</lib-adapt-h-element
        >
        <span [innerHTML]="plainLanguage"></span>
      </div>
      <ol *ngIf="!loaded.noData" #bars class="series">
        <ng-container *ngFor="let item of data; index as index">
          <li tabindex="0" *ngIf="item[raw.chart.yAxisValue] > 0" [id]="id + '-series-item-' + index">
            <span class="content">
              <lib-adapt-h-element
                class="label"
                [id]="id + '-series-item-label-' + index"
                [level]="mapHeadingLvl(headingLvl2)"
                >{{ item[raw.chart.xAxisLabel] | glossary: 'label': lang | async }}</lib-adapt-h-element
              >
              <p
                class="definition"
                [hidden]="dataRepSettings.showGlossary === true ? undefined : true"
                [attr.aria-expanded]="dataRepSettings.showGlossary"
                [id]="id + '-series-item-definition-' + index">
                <span [innerHTML]="item[raw.chart.xAxisLabel] | glossary : 'definition' : lang | async"></span>
              </p>
            </span>
            <div class="bar-wrapper">
              <div
                class="bar "
                [ngStyle]="{ '--usa-data-rep-series-item-flex-amount': item.flexAmount }"
                aria-hidden="true"></div>
              <ul class="details">
                <li class="percentage" [id]="id + '-series-item-percentage-' + index">
                  {{item.percentage?.toLocaleString(this.localization, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                })

                  }}%
                </li>
                <li class="value" [id]="id + '-series-item-value-' + index">
                  {{item[raw.chart.yAxisValue].toLocaleString(this.localization, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                })}}
                </li>
              </ul>
            </div>
          </li>
        </ng-container>
      </ol>

      <div *ngIf="loaded.noData" class="no-data-warning display-flex gap-2 bg-accent-warm-lighter margin-2 padding-2">
        <div class="errors-icon">
          <i class="fal font-xl fa-exclamation-circle"></i>
        </div>
        <div class="errors-body display-flex flex-column gap-2">
          <strong>{{content.actions!['section_unavailable']}}</strong>

          <p class="usa-prose">
            {{
              raw.noDataDescription ||
                "We apologize for the inconvenience, but the way we collected this data doesn't allow us to display it with the current filters. "
            }}
          </p>
        </div>
      </div>
    </ng-container>
  </article>
</div>

<article
  [id]="id + '-data-modal'"
  cdkTrapFocus="{{!dataModal.hidden}}"
  class="modal"
  role="dialog"
  aria-modal="true"
  [attr.aria-labelledby]="id + '-data-modal-title'"
  hidden
  #dataModal>
  <span [id]="id + '-data-modal-dismissal'" class="dismissal" aria-hidden="true" (click)="closeModal()"></span>
  <section class="modal-content" role="document">
    <div class="display-flex gap-2 flex-justify">
      <h2 id="{{ id }}-data-modal-title" class="modal-title">{{ header | glossary: 'label': lang | async }} {{ filterOrSuppress }}</h2>
      <button [id]="id + '-close-modal-button'" #dataModalCloseBtn class="close-modal" (click)="closeModal()">
        Close
        <i class="fas fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <p class="description" [innerHTML]="insight"></p>
    <p *ngIf="suppressed" class="redaction-note">
      <strong>{{content?.actions!['note']}}</strong> {{content?.sections?.[4]?.description}}
    </p>
    <div class="table-content-downloads display-flex gap-2">
      <button (click)="downloadData('csv')" class="usa-button usa-button--unstyled">{{content?.actions!['download_csv']}}</button>
      <button (click)="downloadData('xlsx')" class="usa-button usa-button--unstyled">{{content?.actions!['download_excel']}}</button>
    </div>
    <table #dataTable class="data-table" [attr.aria-label]="header + '-data table'">
      <thead>
        <tr class="header-row">
          <th scope="col" class="th label">{{ raw.dataLabel || 'Label' }}</th>
          <th scope="col" class="th percentage">{{content?.actions!['percent']}}</th>
          <th scope="col" class="th value">{{content?.actions!['value_label']}}</th>
        </tr>
      </thead>
      <tbody>
        <tr tabindex="0" class="flex-row" *ngFor="let item of data">
          <th scope="row" class="td label">{{ item[raw.chart.xAxisLabel] | glossary: 'label' : lang | async }}</th>
          <td class="td percentage">
            <ng-container *ngIf="suppressed; else notSuppressedPct">
              {{ item?.percentage > 0 ? item.percentage.toLocaleString(
                            this.localization,
                            {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            }
                            ) + "%" : content!.actions!['suppressed'] || 'Suppressed' }}
            </ng-container>

            <ng-template #notSuppressedPct>
              {{item.percentage.toLocaleString(
                            this.localization,
                            {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            }
                            )



              }}%
            </ng-template>
          </td>

          <td class="td value">
            <ng-container *ngIf="suppressed; else notSuppressed">
              {{item[raw.chart.yAxisValue] > 0 ? item[raw.chart.yAxisValue].toLocaleString(this.localization, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              }) : content!.actions!['suppressed'] || 'Suppressed'}}
            </ng-container>

            <ng-template #notSuppressed>
              {{item[raw.chart.yAxisValue].toLocaleString(this.localization, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              })}}
            </ng-template>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="footer-row" tabindex="0">
          <td colspan="3" class="tf">
            Total:
            <strong>
              {{this.total.toLocaleString(this.localization, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            })}}
            </strong>
          </td>
        </tr>
      </tfoot>
    </table>
  </section>
</article>
